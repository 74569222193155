.MainLine {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    /* font-weight: bold; */
    background: white;
    /* padding: 10px 100px; */
    padding: 0px 40px;
    border-radius: 41px;
    font-size: larger;
    height: 49px;
    align-items: center;
    width: 600px;
    /* transition: transform 0.5s linear; */

}

.messagebox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
}

.fixedHeight {
    height: 1rem !important;
}



.animateIn {
    /* animation: fadeIn 5ms ease; */
    animation: fadeIn;
    animation-duration: 30s;
    animation-timing-function: cubic-bezier(0.25, 0.50, 0.75, 0.99);
}

@keyframes fadeIn {
    from {
        opacity: 0.4;
        transform: translateY(40px);
        /* Start from original position */
    }

    to {
        opacity: 1;
        transform: translateY(-500px);
        /* Move upwards by 50px */
    }
}


.textCenter {
    text-align: center !important;
}

.opacity50 {
    opacity: 0.5;
}

.opacity70 {
    opacity: 0.7;
}

@media screen and (max-width: 576px) {
    .messagebox {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70vh;
        flex-direction: column;
        padding: 50px 10px;
        font-size: smaller;
    }

    .MainLine {
        display: flex;
        margin-top: 5px;
        margin-bottom: 5px;
        background: white;
        padding: 0px 40px;
        border-radius: 41px;
        font-size: small;
        height: 49px;
        align-items: center;
        justify-items: center;
        justify-content: center;
        width: 300px;

    }

    .textCenter {
        text-align: center !important;
    }

    .opacity50 {
        opacity: 0.5;
    }

    .opacity70 {
        opacity: 0.7;
    }

    .fixedHeight {
        height: 1rem !important;
    }


}