.logo{
    width: 164px;
    height: 22.88px;
}

.navbar__box{
    padding: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    z-index: 3 !important;

}

.mobileLogo{
    display: none;
}

.Box__End__part__Nav{
    gap: 18px;
}

.navbar__descriptions_mob{
    display: none;
}

.navbar__descriptions_web{
    display: block;
}

@media screen and (max-width: 430px) and (min-width: 270px) {
    .logo{
        display: none;
    }
    .mobileLogo{
        display: block;
        width: 40px;
        height: 40px;
        margin-right: 18px;
    }

    .navbar__descriptions_mob{
        display: block;
        font-size: 12px;
        text-align: end;
        margin: 0px;
    }
    .navbar__descriptions_web{
        display: none;
    }
    .Box__End__part__Nav{
        gap: 8px;
    }
}