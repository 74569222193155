
.App{
    background-color: rgba(246, 246, 246, 1);
    min-height: 100vh;
}

.inputContainer{
   position: fixed;
   bottom: 20px !important;
}

.msg-heading-2{
    display: inline-block;
    background: rgba(232, 232, 232, 1);
    color: rgba(47, 47, 47, 1);
    padding: 10px 20px  ;
    border-radius: 0px 20px 20px 20px;
    white-space: pre-line;
}
.msg-heading-1{
    display: inline-block;
    background: rgba(164, 59, 193, 1);
    color: #fff;
    padding: 10px 20px  ;
    border-radius: 17px 0px 17px 17px;
   
    white-space: pre-line;
}

.transitions__details__Box{
  background: rgba(232, 232, 232, 1);
  color: rgba(47, 47, 47, 1);
  padding: 10px 20px  ;
  border: 1px solid rgba(232, 232, 232, 0.5);
  /* border-radius: 12px; */
  border-radius: 0px 20px 20px 20px;
  margin-top: 1rem;
}

/* .transactions__boxes.active {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);

} */

.msg-text{
    font-family: Montserrat;
    font-size: 14px; 
    font-weight: 500;
    line-height: 19.5px;
}

.message {
    max-width: 52%;
}

.messages-container{
    padding-right: 2.5rem;
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
    padding-top: 2rem;
    /* padding-right: 1rem; */
    /* height: 70vh;
    overflow: auto; */


}

.sender-icons{
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #f1bfff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(164, 59, 193, 1);

}

.transitions__details_container{
  /* margin-left: 3.1rem; */
}

.transitions__details, .transitions__details__balence{
  margin: 4px ;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.5px;
}

.transitions__details_date{
  font-family: Montserrat;
  margin: 4px ;
  font-weight: 400;
  font-size: 12px;
  line-height: 17.07px;
  color: rgba(156, 156, 156, 1);
}

.transitions__details__amount{
  font-family: Montserrat;
  margin: 4px ;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.5px;
}
.transitions__details__status{
  font-family: Montserrat;
  margin: 4px ;
  font-weight: 400;
  font-size: 12px;
  line-height: 17.07px;
}

.transitions__details__name{
  font-family: Montserrat;
  margin: 4px ;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.5px;
}

.transitions__details__account__No ,.transitions__details__account__type{
  margin: 4px ;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.63px;
}

.color__of__type__green{
  color: rgba(16, 231, 51, 1);
}
.color__of__type__red{
  color: rgba(214, 0, 52, 1);
}

.transactions__boxes{
  padding: 4px 18px ;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid  rgba(232, 232, 232, 0.5);
  border-radius: 10px;
  margin-top: 1rem;
  cursor: pointer;

}
.transactions__boxes_Fileurl{
  padding: 4px 18px ;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid  rgba(232, 232, 232, 0.5);
  border-radius: 10px;
  margin-top: 1rem;
  border-radius: 0px 20px 20px 20px;


}

.transitions__title{
  margin: 4px;
}

.cancel__btn{
  font-family: Montserrat;
  padding: 8px 22px ;
  border-radius: 39px;
  background-color: #fff!important;
  color: rgba(164, 59, 193, 1);
  border: 1px solid rgba(241, 191, 255, 1);
  cursor: pointer;
}

.pay__btn{
  font-family: Montserrat;
  padding: 8px 28px ;
  border-radius: 39px;
  background-color: rgba(241, 191, 255, 1) !important;
  color: rgba(164, 59, 193, 1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;

}

.Select__btn{
  font-family: Montserrat;
  background-color: rgba(241, 191, 255, 1);
  color: rgba(164, 59, 193, 1);
  padding: 8px 35px 8px 35px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.chat__Icon{
  margin-top: 1rem;
  width: 40px;
  height: 40px;
}

::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the track */
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; /* Color of the handle */
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the handle on hover */
  }

  /* .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
    color: #fff !important;
  } */

  /* .send-icon{
    background:rgba(232, 232, 232, 0.5)
  }
  .send-icon:hover{
    background:rgba(232, 232, 232, 0.5)
  } */

  /* .css-1uvydh2{
    color: #fff !important;
  } */

  .video-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -1; /* Ensure the video stays behind other content */
}

.video-background video {
    /* Make the video fill the entire container */
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content {
    /* Style your content */
    position: relative;
    z-index: 1; /* Ensure the content is above the video */
    /* Add additional styling as needed */
}

.spinner__box{
  margin:  6px  40px;
}

.overflow__box{
  max-height: 68vh;
  overflow: auto;
}

@media screen and (max-width: 430px) and (min-width: 270px) {
  .message {
    max-width: 90%;
}

.messages-container{
  padding-right: 0.5rem;
}

.transactions__boxes {
  padding: 4px 10px !important;
}
.sender-icons{
  display: none;
}

.transitions__details__Box{
  padding:  10px !important;
}
.transitions__details  ,.transitions__details__balence,.transitions__details__account__No ,.transitions__details__account__type {
  font-size: 12px !important;
  word-wrap: break-word;
}
.transitions__details__name{
  font-size: 12px !important;
  word-wrap: break-word;
}
.msg-text{
  font-size: 12px !important;
}
.transitions__details_container{
  margin-left: 0rem;
}
.message {
  max-width: 85%;
}
.overflow__box{
  margin-top: 3rem;
  max-height: 73vh;
  overflow: auto;
}
.sernder__icons_Mobile{
  display: none;
}
.transitions__details_date{
  font-size: 12px;
}
.transitions__details__amount{
  font-size: 12px;
}
.Select__btn{
  padding: 8px 12px;
}
.pay__btn{
  padding: 8px 18px;
  margin-top: 14px;
}
.cancel__btn{
  padding: 8px 18px;
}
.chat__Icon{
  display: none;
}

}
