.login-container{
    background-image: url('../Assets/loginScreenGif.gif');
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center center; 
    height: 100vh;
}

.login__title{
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 600;
    line-height: 39.01px;
    margin: 0px;
}

.login__sub__title{
    background-color: rgba(164, 59, 193, 1);
    color: #fff;
    font-size: 32px;
    font-family: Montserrat;
    font-weight: 600;
    padding:  2px  5px ;
}

.login__descriptions{
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.94px;
}

.full__name{
    margin: 6px 0px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    color: rgba(47, 47, 47, 1);
}
.name__user{
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
}
.email{
    margin: 6px 0px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    color: rgba(47, 47, 47, 1);
}
.organization__name{
    margin: 6px 0px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    color: rgba(47, 47, 47, 1);
}
.mobile__no{
    margin: 6px 0px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    color: rgba(47, 47, 47, 1);
}


    .login-btn{
        width: 100%;
        background-color: rgba(164, 59, 193, 1) !important;
        color: #fff !important;
        border: none;
        border-radius: 10px !important;
        font-weight: 600;
        text-transform: capitalize !important;
        font-size: 1rem !important;
    }

.left__side__form{
    margin-top: 4%  !important;
}

.right__side__form{
    margin-top: 2%  !important;
}
.right_side_gif{
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.verified__card{
    padding: 64px 44px 64px 44px;
    margin: 1rem;
    border-radius: 30px !important;
    text-align: center;
}

.popup__title{
    font-size: 30px;
    font-weight: 600;
    line-height: 36.57px;
    margin: 0px;
    margin-bottom: 12px;
}

.popup__descriptions{
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px ;
    margin: 0px;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
    font-family: Montserrat !important; 
}

/* .login__first__screen{
    display: none !important;
} */


.loginFirstScreen{
    display: none !important;
}
.loginFirstScreen1{
    display: none !important;
}


.css-eekdq4-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input{
    font-family: Montserrat !important; 
}


@media screen and (max-width: 430px) and (min-width: 270px) {

.login-container{
    display: none !important;
}

.login__first__screen{
    padding: 1rem;
    padding-top: 4%;
    background-image: url('https://s3.ap-south-1.amazonaws.com/quiz.vkonnecthealth.com/vifi-assests/firstBackground.gif');
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center center; 
    height: 100vh;
    display: block;

}
.login__secondpage__title{
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    margin: 8px;
}

.login__secondpage__descriptions{
    font-size: 12px;
    font-weight: 400;
    line-height: 24.38px;
    color: rgba(156, 156, 156, 1);
    margin: 4px;
}

.login__sub__secondpage__title{
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    color: #fff;
    margin: 4px;
    padding: 3px;
    background-color: rgba(164, 59, 193, 1);
}

.loginFirstScreen{
    display: block  !important;
    /* height: 100vh; */
}
.loginFirstScreen1{
    display: block  !important;
    /* height: 100vh; */
}

.arrow-container{
    width: 100px;
    height: 100px;
    margin: 0 auto;
    position: absolute;
    /* bottom: 45px; */
    left: 0;
    right: 0;
  }
  
  .arrow-1{
    width: 100px;
    height: 100px;
    background: rgba(164, 59, 193, 1);
    opacity: 0.5;
    border-radius: 50%;
    position: absolute;
  }
  
  .arrow-2{
    width: 60px;
    height: 60px;
    background: rgba(164, 59, 193, 1);
    border-radius: 50%;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
    display: table;
  }
  
  .arrow-2:before{
    width: 52px;
    height: 52px;
    content: "";
    border: 2px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
  }
  
  .arrow-2 i.fa{
    font-size: 30px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: #006064;
  }
  
 /* Custom Animate.css */

.animated.hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite; /* Add this */
    animation-iteration-count: infinite; /* Add this */
    -webkit-animation-fill-mode: forwards; /* Add this */
    animation-fill-mode: forwards; /* Add this */
  }
  
  @-webkit-keyframes zoomIn {
    0% {
      opacity: 0;
      -webkit-transform: scale3d(.4, .4, .4);
      transform: scale3d(.4, .4, .4);
    }
  
    50% {
      opacity: 0.5;
    }
  
    100% {
      opacity: 0;
    }
  }
  
  @keyframes zoomIn {
    0% {
      opacity: 0;
      -webkit-transform: scale3d(.4, .4, .4);
      transform: scale3d(.4, .4, .4);
    }
  
    50% {
      opacity: 0.5;
    }
  
    100% {
      opacity: 0;
    }
  }
  
  .zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
  }
  
}